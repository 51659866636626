<template>
  <div>
    <!-- Title -->
    <b-card-title class="text-primary mb-3">Sign In</b-card-title>

    <check-user-credentials @success="successCheckCredentials" />
  </div>
</template>

<script>
import CheckUserCredentials from "../../components/auth/CheckUserCredentials.vue";

export default {
  components: { CheckUserCredentials },
  data() {
    return {
      params: this.$route.params,
      query: this.$route.query,
    };
  },
  methods: {
    successCheckCredentials(data) {
      const mobile = data.mobile;
      const params = { ...this.params, mobile };
      const query = this.query;
      if (data.exists) {
        this.$router.push({ name: "Authenticate", params, query });
      } else {
        this.$router.push({ name: "Register", params, query });
      }
    },
  },
};
</script>

<style></style>
